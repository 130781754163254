<template>
  <v-container>
    <v-progress-linear
      v-if="loading"
      indeterminate
    />
    <v-card v-else>
      <v-card-text>
        <v-row>
          <v-col sm="12" md="6">
            <div class="d-flex align-center">
              <v-icon size="4em" class="mr-4">mdi-domain</v-icon>
              <span class="text-h4 font-weight-regular">{{ company.name }}</span>
            </div>
            <div class="mt-3 ml-1">
              Type de l'entreprise : {{ company.type | prettyCompanyType }}
            </div>
          </v-col>
          <v-col sm="6" md="4" class="d-flex align-center justify-center">
            <div>
              <v-icon class="mr-3">mdi-map-marker</v-icon>
            </div>
            <div class="mr-3">
              {{ company.address.firstLine }}<br>
              <span v-if="company.address.secondLine">{{ company.address.secondLine }}<br></span>
              {{ company.address.postalCode }} {{ company.address.city }}
            </div>
          </v-col>
          <v-col sm="6" md="2">
            <v-btn
              v-if="$store.state.auth.user.isAdmin"
              block color="info" text
              @click="$refs.editForm.open(company)"
            >
              <v-icon left>mdi-pencil</v-icon> Modifier
            </v-btn>
            <v-btn
              v-if="$store.state.auth.user.isAdmin"
              block class="mt-2" color="error" text
              @click="$refs.removeForm.open(company)"
            >
              <v-icon left>mdi-delete</v-icon> Supprimer
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="mt-3">
      <v-tabs
        v-model="activeTab"
        exact
        centered
        fixed-tabs
      >
        <v-tab :to="{ name: 'CompanySites' }" exact>
          Chantiers
        </v-tab>
        <v-tab :to="{ name: 'CompanyUsers' }" exact>
          Utilisateurs
        </v-tab>
      </v-tabs>
      <router-view :company="company"></router-view>
    </v-card>
    <EditCompany
      ref="editForm"
      @finished="fetchData"
    />
    <RemoveItem
      resource="compagnies"
      title="cette entreprise"
      ref="removeForm"
      @finished="$router.push({ name: 'Companies' })"
    />
  </v-container>
</template>

<script>
import EditCompany from "../../components/Companies/EditCompany.vue"
import RemoveItem from "../../components/RemoveItem.vue"

export default {
  components: {
    EditCompany,
    RemoveItem
  },
  data: () => ({
    activeTab: '',
    company: {
    },
    loading: true
  }),
  mounted () {
    this.refreshTab()
    this.fetchData()
    if (this.$route.name == 'ViewCompany') {
      this.$router.push({ name: 'CompanySites', params: { id: this.$route.params.id } })
    }
  },
  watch: {
    '$route.path'() {
      this.refreshTab()
    }
  },
  methods: {
    refreshTab() {
      this.activeTab = this.$route.path
    },
    fetchData() {
      this.loading = true
      this.$store.dispatch('companies/fetchOne', this.$route.params.id).then(res => {
        this.loading = false
        this.company = res
      }).catch(() => {
        this.loading = false
      })
    }
  }
}
</script>
